@mixin fontStyle {
  @apply text-white text-base opacity-60;
}

@keyframes scales {
  0% {
    transform: translateY(-50%) scale(1.1);
  }

  50% {
    transform: translateY(-50%) scale(0.9);
  }

  100% {
    transform: translateY(-50%) scale(1);
  }
}

.input {
  @apply flex items-center relative pr-3 h-10 mr-10;
  width: 30px;
  border-radius: 4px;
  height:36px;
  &.inputActive {
    @apply bg-white-10;
    width: 286px;
    padding-left: 18px;

    & > input {
      @apply block flex-1;
    }

    .img {
      @apply right-3;
      width: 20px;
      // background-color: red;
    }
  }

  & > input {
    @apply w-0 h-full bg-transparent border-0 outline-0;
    @include fontStyle;

    &::placeholder {
      @include fontStyle;
    }
  }

  .line{
    position: absolute;
    right: 44px;
    top: 9px;
    width: 1px;
    height: 16px;
    background: rgba(255, 255, 255, 0.2);
  }
  .img {
    @apply absolute top-2/4 right-0 cursor-pointer object-contain;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    &:hover {
      animation: scales 0.5s ease;
    }
  }
}

// 移动端
@media screen and (max-width: 870px) {
  .input {
    &.inputActive {
      width: 120px;
    }
  }
}
@media screen and (min-width: 871px) and (max-width: 1100px) {
  .input {
    &.inputActive {
      width: 220px;
    }
  }
}

@media screen and (min-width: 1101px) and (max-width: 1460px) {
  .input {
    &.inputActive {
      width: 300px;
    }
  }
}
