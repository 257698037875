@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply p-0 m-0 box-border font-normal;
  font-family: 苹方-简;
}

.flexCenter {
  @apply w-full h-full flex justify-center items-center;
}

:root {
  --theme-color: #00da5a;
  --bg-color: #141414;
  --bg-25: #252525;
  --purple-color: rgb(84, 84, 249);
  --video-pc-height: 50px;
  --video-phone-height: 40px;
  --toast-index: 999999; // toast最高级
  --modal-index: 99999; // 弹窗第二级
  --pop-index: 9999; // 弹出确认，回顶部相关
  --content-index: 999; // 内容级
  --shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

a {
  text-decoration: none;
}

body {
  @apply w-screen bg-bg overflow-x-hidden min-h-screen;
  scrollbar-width: 1px;
  &::-webkit-scrollbar {
    @apply w-0 h-0;
  }
}

html {
  font-size: 16px;
}

// 统一设定图片展示规则
img {
  @apply object-cover border-0 outline-0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

// input统一设置
input {
  appearance: none !important;
  color: #fff !important;
  box-shadow: none !important;
}

input:focus {
  color: #fff !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

input:-internal-autofill-selected {
  color: #fff !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  color: #fff !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.layoutMain {
  @apply w-screen relative;
  min-height: 100vh;
  border: 1px solid transparent;
}

// 通用样式
.textEllipsis {
  @apply overflow-hidden whitespace-nowrap text-ellipsis break-all;
}

.notFound {
  @apply flex flex-col justify-center items-center;
  margin-top: 200px;

  a {
    @apply text-white-80;
  }

  p {
    @apply text-xl mt-5 mb-7 text-white-80;
  }
}

.stop-scrolling {
  overflow: hidden !important ;
}

// 去掉video的默认描边
video:focus,
video > *:hover {
  @apply border-0 outline-0;
  box-shadow: none;
}

video::-webkit-media-controls {
  display: none !important;
}

#message-root {
  @apply fixed top-2/4 left-2/4 overflow-hidden flex justify-center items-center flex-col;
  z-index: var(--toast-index);
  transform: translate(-50%, -50%);
}

// 清除a标签，button的默认样式
a,
button {
  @apply outline-0 bg-transparent border-0 appearance-none;
  -webkit-tap-highlight-color: transparent;
}

a:active,
button:active {
  @apply appearance-none bg-transparent border-0 outline-0;
}

.hide {
  display: none !important;
}

.showBlock {
  display: block !important;
}

.showFlex {
  display: flex !important;
}

.mt-18 {
  margin-top: 70px !important;
}
.min-h {
  min-height: 72px !important;
}
@media screen and (max-width: 767px) {
  .hideWhenPhone {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .hideWhenPc {
    display: none !important;
  }
}

/* 隐藏视频控件 */
video::-webkit-media-controls,
video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-panel {
  display: none !important;
}

input:active {
  appearance: none !important;
}
input:focus {
  outline: none !important;
  appearance: none !important;
}
