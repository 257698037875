// 最大屏幕
@media screen and (min-width: 1876px) {
  .history-list-item {
    @apply w-screen flex;

    .history-left {
      @apply flex-1;
    }

    .history-middle {
      @apply overflow-x-hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      .scrollable {
        scrollbar-width: none; /* Firefox */
      }

      width: 1692px;
      .history-item {
        width: 180px;
        .img {
          width: 180px;
          height: 256px;
        }
        .imgLive {
          width: 180px;
          height: 102px !important;
        }
      }
    }

    .history-right {
      @apply flex-1;
    }
  }
}

// 7个
@media screen and (min-width: 1680px) and (max-width: 1875px) {
  .history-list-item {
    @apply w-screen flex;

    .history-left {
      width: 100px;
    }
    .history-middle {
      @apply flex-1 overflow-x-hidden;
      .history-item {
        width: calc((100% - 112px) / 7);
        .img {
          @apply w-full;
          height: calc(((100vw - 616px) / 7) * 1.422) !important;
        }
        .imgLive {
          @apply w-full;
          height: calc(((100vw - 616px) / 7) * 0.566) !important;
        }
      }
    }

    .history-right {
      width: 84px;
    }
  }
}

// 6个排布
@media screen and (min-width: 1484px) and (max-width: 1679px) {
  .history-list-item {
    @apply w-screen flex;
    .history-left {
      width: 100px;
    }

    .history-middle {
      @apply flex-1 overflow-x-hidden;
      .history-item {
        width: calc((100% - 96px) / 6);

        .img {
          @apply w-full;
          height: calc(((100vw - 600px) / 6) * 1.422) !important;
        }
        .imgLive {
          @apply w-full;
          height: calc(((100vw - 600px) / 6) * 0.566) !important;
        }
      }
    }

    .history-right {
      width: 84px;
    }
  }
}

// 5个排布
@media screen and (min-width: 1288px) and (max-width: 1483px) {
  .history-list-item {
    @apply w-screen flex;

    .history-left {
      width: 100px;
    }

    .history-middle {
      @apply flex-1 overflow-x-hidden;
      .history-item {
        width: calc((100% - 80px) / 5);
        .img {
          @apply w-full;
          height: calc(((100vw - 584px) / 5) * 1.422) !important;
        }
        .imgLive {
          @apply w-full;
          height: calc(((100vw - 584px) / 5) * 0.566) !important;
        }
      }
    }

    .history-right {
      width: 84px;
    }
  }
}

// 4个排布
@media screen and (min-width: 1092px) and (max-width: 1287px) {
  .history-list-item {
    @apply w-screen flex;
    .history-left {
      width: 100px;
    }

    .history-middle {
      @apply flex-1 overflow-x-hidden;
      .history-item {
        width: calc((100% - 64px) / 4);

        .img {
          @apply w-full;
          height: calc(((100vw - 568px) / 4) * 1.422) !important;
        }
        .imgLive {
          @apply w-full;
          height: calc(((100vw - 568px) / 4) * 0.566) !important;
        }
      }
    }

    .history-right {
      width: 84px;
    }
  }
}

// 4个排布
@media screen and (min-width: 896px) and (max-width: 1091px) {
  .history-list-item {
    @apply w-screen flex;
    .history-left {
      width: 100px;
    }

    .history-middle {
      @apply flex-1 overflow-x-hidden;
      .history-item {
        width: calc((100% - 48px) / 3);

        .img {
          @apply w-full;
          height: calc(((100vw - 552px) / 3) * 1.422) !important;
        }
        .imgLive {
          @apply w-full;
          height: calc(((100vw - 552px) / 3) * 0.566) !important;
        }
      }
    }

    .history-right {
      width: 84px;
    }
  }
}

// 3个排布
@media screen and (max-width: 895px) {
  .history-list-item {
    @apply flex;
    .history-left {
      @apply w-0;
    }

    .history-middle {
      @apply ml-4;
      width: calc(100vw - 32px);
      margin-left: 16px;
      .history-item {
        @apply mb-4;

        width: calc((100% - 48px) / 3);

        margin-right: 16px;
        .img {
          @apply w-full;
          height: calc((100vw - 74px) / 5 * 1.422) !important;
        }
        .imgLive {
          @apply w-full;
          height: calc((100vw - 64px) / 3 * 0.566) !important;
        }
      }
    }

    .history-right {
      @apply w-0;
    }
  }
}
