.nav {
  @apply w-full fixed flex-nowrap left-0 flex items-center  px-4 overflow-x-auto bg-bg;
  height: 44px;
  top: 42px;
  z-index: var(--content-index);
  scrollbar-width: 0px;
  &::-webkit-scrollbar {
    @apply w-0 h-0;
  }

  & a {
    @apply block;
  }

  .li {
    @apply shrink-0 text-base flex h-6 items-center cursor-pointer mr-4 font-bold;
    color: rgba(255, 255, 255, 0.6);

    & h1 {
      @apply text-base;
    }
    &.liActive {
      @apply text-lg text-theme;
      & > * {
        @apply text-lg text-theme font-bold;
      }
    }
  }
}
