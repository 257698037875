.btn {
  @apply inline-flex justify-center items-center border-0 cursor-pointer text-black text-sm outline-0 rounded h-9 bg-transparent border-none outline-none;
  width: 88px;
  transition: all 0.3s;
  &.opacity:hover {
    opacity: 0.8;
  }
  &.scale:hover {
    animation: scales 0.5s ease;
  }
  &.scaleImg:hover > img {
    animation: scales 0.5s ease;
  }

  & > img {
    @apply object-contain;
    width: 22px;
    height: 22px;
  }
  & > span {
    margin-left: 6px;
  }
}

@keyframes scales {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}
