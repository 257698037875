.header {
  @apply w-full fixed left-0 top-0 flex items-center justify-between px-4 bg-bg;
  height: 50px;
  z-index: var(--pop-index);

  .left {
    @apply flex items-center;
    .img {
      @apply object-contain shrink-0 h-12 w-28;
      height: 50px;
      // background-color: red;
    }
    & > .slogan {
      @apply text-xs;
    }
  }

  .right {
    @apply flex items-center;
    height: 50px;
    & > a {
      @apply ml-6;
    }

    img {
      @apply block;
    }

    .rightUser {
      @apply w-10 h-10 ml-6;
      width: 22px;
      height: 22px;
    }

    .searchIcon {
      @apply object-contain;
      width: 22px;
      height: 22px;
    }

    .searchImg {
      @apply object-contain w-6 h-6;
    }
  }
}

.empty {
  width: 100%;
  height: 81px;
  // background-color: red;
}
