.nav {
  @apply w-fit flex items-center flex-nowrap shrink-0;
  z-index: var(--content-index);
  &::-webkit-scrollbar {
    width: 2px;
  }

  .li {
    @apply flex  items-center  cursor-pointer;
    &,
    & > * {
      @apply text-white-80 text-base font-normal mx-3;
    }
    &:hover {
      & > * {
        @apply text-white;
      }
    }
    & > p,
    & > h1 {
      margin: 0 10px;
    }
  }

  a.liActive {
    & > h1,
    & > p {
      @apply text-theme font-semibold;
    }
    &:hover {
      & > * {
        @apply text-theme;
      }
    }
  }

  .liMore {
    @apply h-20  text-white-80 text-base mx-3 flex items-center relative;
    &:hover {
      & > span {
        @apply text-white;
        cursor: pointer;
      }
      .moreUl {
        @apply block;
      }
    }
    .moreUl {
      @apply hidden h-auto absolute top-16 left-2/4 w-40 bg-bg25;

      // background-color: red;
      border: 1px solid rgba(255, 255, 255, 0.12);
      padding: 10px;
      border-radius: 10px;
      transform: translateX(-50%);
      .liItem {
        @apply h-10 text-base text-white justify-start pl-10;
        width: 140px;
        &:not(:first-child) {
          margin-top: 0px;
        }
        &:hover {
          background-color: #313131;
        }
        &.liItemActive {
          @apply text-theme;
          background-color: #313131;
        }
      }
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 1136px) {
}

@media screen and (max-width: 767px) {
  .navPhone {
    @apply w-full fixed flex-nowrap left-0 flex items-center  px-4 overflow-x-auto bg-bg;
    height: 44px;
    top: 50px;
    z-index: var(--content-index);
    scrollbar-width: 0px;

    &::-webkit-scrollbar {
      @apply w-0 h-0;
    }

    & a {
      @apply block;
    }

    .li {
      @apply shrink-0 text-base flex h-6 items-center cursor-pointer mr-3 font-normal;
      color: rgba(255, 255, 255, 0.6);

      & h1 {
        @apply text-base;
      }
    }

    .liActive {
      @apply text-lg;
      color: var(--theme-color) !important;

      & > h1 {
        @apply text-lg;
        color: var(--theme-color) !important;
      }
    }
  }
}
