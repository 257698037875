//未登录状态 用户信息
.NotLoginUser{
  display: flex;
  flex-direction: column;
  // background-color: red;
  margin-right: 2rem;
  width: 2rem;
  font-size: 11px;
  height: 64px;
  // background-color: red;
  padding: 40px 0;
  color:#fff;
  .icon{
    width: 25px;
    height: 25px;
  }

  .dialog {
    @apply hidden absolute  top-20 pt-3  rounded-lg;
    display: none;
    transform: translateX(-50%);
    width: 230px;
    top:63px;
    right: -60px;
    background-color: rgb(26, 28, 34);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 3.6px;
    display: none;
    padding:20px;
    z-index: 999;
    .line{
      width: 100%;
      height: 1px;
      margin-top: 15px;
      background: rgb(55, 56, 61);
    }
    .anzhuo{
        display: flex;
        justify-content: space-between;
        font-size: small;
        margin-top: 20px;
        .left{
          .title{
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            min-width: 130px;
            text-align: left;
            font-size: 12px;
            line-height: 14px;
            color: rgb(242, 191, 131);
            font-weight: 500;
            margin-bottom: 12px;
          }
          .info{
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            min-width: 130px;
            text-align: left;
            font-size: 12px;
            color: rgb(131, 132, 134);
            font-weight: 400;
            line-height: 14px;
          }
        }
        .right{
          width: 60px;
          height: 60px;
        }
    }
    .tv{
      .title{
        font-weight: bold;
        color: #fff;
        font-size: small;
      }
      .btn{
        color: #fff;
        font-size: small;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin-top: 12px;
        background: rgb(28, 199, 73);
        border-radius: 4px;
        width: 100%;
        height: 30px;
        color: rgb(255, 255, 255);
        font-size: 13px;
        cursor: pointer;
        padding: 0 10px;
      }
    }
    &::after{
      display: block;
      box-sizing: border-box;
      content: "";
      z-index: 999999 !important;
      height: 12px;
      width: 12px;
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: rgb(26, 28, 34);
      border-left: 1px solid rgba(255, 255, 255, 0.25);
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 4px 0px 0px;
      // background-color: red;

    }
    .joinImg {
      width: 100px;
      height: 100px;
      border-radius: 3px;
    }
    .joinText{
      padding:10px 0;
    }
    
  }

  &:hover {
    .dialog {
      @apply block;
    }
  }
}



//登录用户
.LoginUser{
  display: flex;
  flex-direction: column;
  // background-color: red;
  margin-right: 2rem;
   width: 40px;
  font-size: 11px;
  height: 64px;
  // background-color: red;
  padding: 0px 0;
  color:#fff;
  .icon{
    width: 37.67px;
    border-radius: 50%;
    background-color: #323232;
    height: 37.67px;
  }

  .dialog {
    @apply hidden absolute  top-20 pt-3  rounded-lg;
    display: none;
    transform: translateX(-50%);
    width: 230px;
    top:63px;
    right: -60px;
    background-color: rgb(26, 28, 34);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 3.6px;
    display: none;
    padding:0px;
    z-index: 999;
    .user{
      width: 100%;
      z-index: 1000;
      display: flex;
      padding: 10px 20px;
      align-items: center;
      padding: 16px 0px 16px 16px;
      background-size: 100%;
      color: rgb(255, 255, 255);
      .avatar{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        // background-color: red;
        border: 1px solid rgb(55, 56, 61);
      }
      .info{
        margin-left: 5px;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        vertical-align: middle;
        max-width: 200px;
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        .email{

        }
        .time{
          font-size: 11px;
          color: rgb(242, 191, 131);
        }
      }
    }

    .list{
      display: flex;
      flex-direction: row;
      padding:10px 20px;
      // justify-content: center;
      align-items: center;
      justify-content: space-between;
      .left{
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon{
          width: 30px;
          height: 30px;
        }
        .content{
          margin-left: 10px;
          font-size: 13px;
        }
      }
      .right{
        .iconx{
          width: 10px;
          height: 10px;
        }
      }
    }

    .exitText {
      @apply text-xs leading-5 bg-theme text-white  cursor-pointer text-center;
      margin-top: 26px;
      font-family: Alibaba PuHuiTi 2;
      padding: 6px 10px;
      margin: 20px;
      border-radius: 5px;
      // background-color: #ffffff40;
      &:hover {
        @apply opacity-100;
      }
    }

    .line{
      height: 1px;
      margin: 0 20px;
      margin-top: 0px;
      background: rgb(55, 56, 61);
    }
    
    &::after{
      display: block;
      box-sizing: border-box;
      content: "";
      z-index: 999999 !important;
      height: 12px;
      width: 12px;
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: rgb(26, 28, 34);
      border-left: 1px solid rgba(255, 255, 255, 0.25);
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 4px 0px 0px;
      // background-color: red;
    }
   
    
  }

  &:hover {
    .dialog {
      @apply block;
    }
  }
}





.loginUser {
  @apply h-full text-center flex items-center relative;

  .head {
    height: 34px;
    width: 34px;
    margin-right: 6px;
  }

  .name {
    @apply block text-sm text-white w-10;
  }

  &:hover .loginMenu {
    @apply block;
  }

  .loginMenu {
    @apply hidden absolute right-0 rounded-lg p-5 bg-bg25;
    top: 64px !important;
    width: 300px;

    .userInfo {
      @apply flex items-center relative w-full;
      height: 48px;

      .vipImg {
        @apply relative shrink-0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 10px;
        background-image: url('/api/images/user');
        background-size: 100% 100%;

        &.vipActive {
          border: 2px solid #ffd1b0;
        }

        &.vipInActive {
          border: 2px solid #d8d8d8;
        }

        .vipLogo {
          @apply absolute bottom-0 right-0;
          width: 14px;
          height: 14px;
        }
      }

      .vipDate {
        &.ellipsis {
          width: calc(100% - 150px);
        }
        &.full {
          @apply flex-1;
        }
        & > p {
          @apply text-left;
        }
        .email {
          @apply text-lg text-white text-white;
          line-height: 30px;
          font-family: Alibaba PuHuiTi 2;
        }

        .pActive {
          @apply text-xs leading-3;
          font-family: Alibaba PuHuiTi 2;
          line-height: 10px;
          background: linear-gradient(90deg, #ffbd8d 0%, #ffe5d8 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        .pInActive {
          @apply text-white-60 text-xs;
          font-family: Alibaba PuHuiTi 2;
          line-height: 10px;
        }
      }

      .toVip {
        @apply ml-4 leading-5 text-sm;
        width: 72px;
        height: 30px;
        background: linear-gradient(90deg, #ffbd8d 0%, #ffe5d8 100%);
        font-family: Alibaba PuHuiTi 2;
        color: #7c3c19;
      }
    }

    .userTabMenu {
      @apply mt-5 flex justify-evenly;

      .tabMenuItems {
        @apply opacity-60 w-20 rounded flex flex-col items-center justify-center text-white;

        &:hover {
          @apply opacity-100 cursor-pointer;
        }

        height: 76px;
        background-color: #323232;

        & > img {
          margin-bottom: 6px;
          width: 30px;
          height: 30px;
        }

        & > span {
          @apply m-0 text-xs whitespace-nowrap;
          line-height: 18px;
        }
      }
    }

    .exitText {
      @apply text-xs leading-5 bg-theme text-white  cursor-pointer text-center;
      margin-top: 26px;
      font-family: Alibaba PuHuiTi 2;
      padding: 6px 10px;
      margin: 20px;
      border-radius: 20px;
      background-color: #ffffff40;
      &:hover {
        @apply opacity-100;
      }
    }
  }
}

.notLogin {
  @apply h-8 w-20 bg-theme cursor-pointer text-base font-semibold text-white;
  background: #ffffff40;
  border-radius: 20px;
}
