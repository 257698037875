.searchBox {
  @apply absolute right-0  rounded-lg bg-bg25 py-3;
  top: 50px;
  width: 285px;
  max-height: 346px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  .menu {
    @apply h-5 flex justify-between items-center text-sm px-5 text-c99;
    margin-bottom: 7px;

    & > span {
      @apply cursor-pointer;
    }

    .menuBtn {
      @apply cursor-pointer h-5 text-xs flex items-center;

      & > img {
        @apply w-5 h-5 ml-1;
      }
    }
  }

  .searchList {
    @apply flex flex-wrap w-full overflow-hidden mt-3 px-5;
    max-height: 66px;
    margin-bottom: 6px;

    .item {
      @apply cursor-pointer h-7 leading-7 px-4 text-white text-sm rounded;
      background: rgb(26, 28, 34);
      margin-right: 10px;
      margin-bottom: 10px;

      &:hover {
        @apply bg-bg66;
      }
    }
  }

  .hotSearch {
    @apply flex items-center px-5 w-full text-sm text-white;
    height: 30px;

    &:hover {
      @apply cursor-pointer;
      background-color: #343434;
    }

    .hotIndex {
      @apply w-5 h-5 text-center leading-5 rounded;
      // background-color: #757575;


      &.one {
        color:#1CC749;
        // background-color: #f92828;
      }

      &.two {
        color:#1CC749;
      }

      &.three {
        color:#1CC749;
      }
    }

    .hotName {
      @apply flex-1;
      margin: 0 10px;
    }

    .hotLabel {
      @apply text-xs h-5 text-center leading-5 text-theme rounded;
      padding: 0 10px;
      background-color: rgba(255, 128, 50, 0.1);
    }

    .hotLabelLive {
      background-color: rgba(51, 156, 255, 0.1);
      color: rgb(51, 156, 255);
    }
  }
}
