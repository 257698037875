.headerPlayPage {
  background-color: #141414 !important;
}
.header {
  @apply w-screen h-16 fixed left-0 top-0 flex items-center;
  // background-color: red !important;
  padding: 0 56px !important;
  z-index: var(--pop-index);
  & > .img {
    @apply block top-2/4 z-inherit w-32 h-7 bg-transparent mr-2;

    & img {
      @apply w-full h-full;
      margin-top: 0px;
    }
  }

  & > .slogan {
    font-size: 0;
  }
}

// 移动端
@media screen and (max-width: 1100px) {
  .header {
    @apply p-4;
  }
}
