//小尺寸的时候搜索框缩小
@media screen and (max-width: 1023px) and (min-width: 768px) {
}

.rightMenu {
  @apply absolute right-12 top-0 h-full flex items-center pl-2 z-inherit;
  // margin-right: 64px !important;

  .downloadBtn {
    @apply h-full relative mr-10;

    color: #fff;

    width: 80px;

    &:hover {
      .app {
        color: rgb(28, 199, 73);
        border: 1px solid rgb(28, 199, 73);
      }
    }
    .app {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      border: 1px solid #fff;
      border-radius: 2px;
      font-size: small;
      font-weight: bold;
      // margin-left: 5px;
    }
    .dialog {
      @apply hidden absolute left-2/4 top-20 pt-3  rounded-lg;
      display: none;
      transform: translateX(-50%);
      width: 230px;
      top: 63px;
      right: 20px;
      background-color: rgb(26, 28, 34);
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 3.6px;
      display: none;
      padding: 20px;
      z-index: 999;
      .line {
        width: 100%;
        height: 1px;
        margin-top: 15px;
        background: rgb(55, 56, 61);
      }
      .anzhuo {
        display: flex;
        justify-content: space-between;
        font-size: small;
        margin-top: 20px;
        .left {
          .title {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            min-width: 130px;
            text-align: left;
            font-size: 12px;
            line-height: 14px;
            color: rgb(242, 191, 131);
            font-weight: 500;
            margin-bottom: 12px;
          }
          .info {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            min-width: 130px;
            text-align: left;
            font-size: 12px;
            color: rgb(131, 132, 134);
            font-weight: 400;
            line-height: 14px;
          }
        }
        .right {
          width: 60px;
          height: 60px;
        }
      }
      .tv {
        .title {
          font-weight: bold;
          color: #fff;
          font-size: small;
        }
        .btn {
          color: #fff;
          font-size: small;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          margin-top: 12px;
          background: rgb(28, 199, 73);
          border-radius: 4px;
          width: 100%;
          height: 30px;
          color: rgb(255, 255, 255);
          font-size: 13px;
          cursor: pointer;
          padding: 0 10px;
        }
      }
      &::after {
        display: block;
        box-sizing: border-box;
        content: '';
        z-index: 999999 !important;
        height: 12px;
        width: 12px;
        position: absolute;
        top: -1px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: rgb(26, 28, 34);
        border-left: 1px solid rgba(255, 255, 255, 0.25);
        border-top: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 4px 0px 0px;
        // background-color: red;
      }
      .joinImg {
        width: 100px;
        height: 100px;
        border-radius: 3px;
      }
      .joinText {
        padding: 10px 0;
      }
    }

    &:hover {
      .dialog {
        @apply block;
      }
    }
  }
  .rightBtn {
    @apply w-8 h-full relative mr-8;
    display: flex;
    flex-direction: column;
    color: #fffc;
    font-size: 11px;
    & > img {
      @apply w-full h-7;
    }
    &.search {
      width: 400px;
    }

    .icon {
      width: 25px;
      height: 25px;
    }
    //vip
    &:hover {
      .dialogVip {
        display: block;
      }
    }
    .dialogVip {
      @apply hidden absolute left-2/4 top-20 pt-3  rounded-lg;
      display: none;
      transform: translateX(-50%);
      width: 250px;
      top: 65px;
      background-color: rgb(26, 28, 34);
      border: 1px solid rgba(255, 255, 255, 0.25);
      border-radius: 3.6px;
      display: none;
      padding: 20px;
      z-index: 999;
      .quanyi {
        display: flex;
        width: 100%;
        .itemX {
          width: 100px;
          font-size: 10px;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            width: 40px;
            height: 40px;
          }
        }
      }

      .line {
        width: 100%;
        height: 1px;
        margin-top: 15px;
        background: rgb(55, 56, 61);
      }
      .anzhuo {
        display: flex;
        justify-content: space-between;
        font-size: small;
        margin-top: 20px;
        .left {
          .title {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            min-width: 130px;
            text-align: left;
            font-size: 12px;
            line-height: 14px;
            color: rgb(242, 191, 131);
            font-weight: 500;
            margin-bottom: 12px;
          }
          .info {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            min-width: 130px;
            text-align: left;
            font-size: 12px;
            color: rgb(131, 132, 134);
            font-weight: 400;
            line-height: 14px;
          }
        }
        .right {
          width: 60px;
          height: 60px;
        }
      }
      .tv {
        margin-top: 20px;
        .title {
          font-weight: bold;
          color: #fff;
          font-size: small;
        }
        .btn {
          color: #fff;
          font-size: small;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          margin-top: 12px;
          background: rgb(28, 199, 73);
          background-color: rgb(242, 191, 131);
          border-radius: 4px;
          width: 100%;
          height: 30px;
          color: #000;
          font-weight: bold;
          font-size: 13px;
          cursor: pointer;
          padding: 0 10px;
        }
      }
      &::after {
        display: block;
        box-sizing: border-box;
        content: '';
        z-index: 999999 !important;
        height: 12px;
        width: 12px;
        position: absolute;
        top: -1px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: rgb(26, 28, 34);
        border-left: 1px solid rgba(255, 255, 255, 0.25);
        border-top: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 4px 0px 0px;
        // background-color: red;
      }
      .joinImg {
        width: 100px;
        height: 100px;
        border-radius: 3px;
      }
      .joinText {
        padding: 10px 0;
      }
    }
    //tg群
    &.join {
      &:hover {
        .joinClub {
          @apply flex;
        }
      }
      .joinClub {
        @apply hidden absolute left-2/4 top-20 pt-3  rounded-lg;
        transform: translateX(-50%);
        width: 150px;
        top: 64px !important;
        background-color: rgb(26, 28, 34);
        border: 1px solid rgba(255, 255, 255, 0.25);
        border-radius: 3.6px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: none;
        &::after {
          display: block;
          box-sizing: border-box;
          content: '';
          z-index: 999999 !important;
          height: 12px;
          width: 12px;
          position: absolute;
          top: -1px;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: rgb(26, 28, 34);
          border-left: 1px solid rgba(255, 255, 255, 0.25);
          border-top: 1px solid rgba(255, 255, 255, 0.25);
          border-radius: 4px 0px 0px;
          // background-color: red;
        }
        .joinImg {
          width: 100px;
          height: 100px;
          border-radius: 3px;
        }
        .joinText {
          padding: 10px 0;
        }
      }
    }
  }
}

// 移动端
@media screen and (max-width: 1100px) {
  .rightMenu {
    @apply mr-4;
  }
}
