// 最大屏幕1920及以上
@media screen and (min-width: 1919px) {
  .responsive {
    @apply w-screen flex;

    .responsive-left {
      @apply flex-1;
    }
    .responsive-right {
      @apply flex-1;
    }
    .responsive-center {
      @apply shrink-0;
      max-width: calc(100vw - 112px);
      .items {
        @apply w-full;
        .item {
          @apply mr-7;
          width: 232px;
          height: 438px;
        }
        .itemWidth {
          @apply mr-7;
          width: 232px;
        }
        .itemLive {
          @apply mr-7;
          width: 232px;
          height: 140px;
        }
      }
    }
  }
}

// 6个
@media screen and (min-width: 1617px) and (max-width: 1919px) {
  .responsive {
    @apply w-screen flex;

    .responsive-left {
      @apply shrink-0;
      width: 56px;
    }
    .responsive-right {
      @apply shrink-0;
      width: 42px;
    }
    .responsive-center {
      @apply flex-1;
      .items {
        @apply w-full;
        .item {
          @apply mr-7;
          width: calc((100% - 72px) / 6);
          height: calc(((100vw - 168px) / 6) * 1.5);
        }
        .itemWidth {
          @apply mr-7;
          width: calc((100% - 168px) / 6);
        }
        .itemLive {
          @apply mr-7;
          width: calc((100% - 168px) / 6);
          height: calc(((100vw - 168px) / 6) * 0.5616);
        }
      }
    }
  }
}

// 5个排布
@media screen and (min-width: 1313px) and (max-width: 1617px) {
  .responsive {
    @apply w-screen flex;

    .responsive-left {
      @apply shrink-0;
      width: 56px;
    }
    .responsive-right {
      @apply shrink-0;
      width: 42px;
    }
    .responsive-center {
      @apply flex-1;
      .items {
        @apply w-full;
        .item {
          @apply mr-7;
          width: calc((100% - 60px) / 5);
          height: calc(((100vw - 156px) / 5) * 1.5);
        }
        .itemWidth {
          @apply mr-7;
          width: calc((100% - 140px) / 5);
        }
        .itemLive {
          @apply mr-7;
          width: calc((100% - 140px) / 5);
          height: calc(((100vw - 156px) / 5) * 0.5616);
        }
      }
    }
  }
}

// 4个排布
@media screen and (min-width: 768px) and (max-width: 1313px) {
  .responsive {
    @apply w-screen flex;

    .responsive-left {
      @apply shrink-0;
      width: 56px;
    }
    .responsive-right {
      @apply shrink-0;
      width: 42px;
    }
    .responsive-center {
      @apply flex-1;
      .items {
        @apply w-full;
        .item {
          @apply mr-7;
          width: calc((100% - 48px) / 4);
          height: calc(((100vw - 144px) / 4) * 1.5);
        }
        .itemWidth {
          @apply mr-7;
          width: calc((100% - 112px) / 4);
        }
        .itemLive {
          @apply mr-7;
          width: calc((100% - 112px) / 4);
          height: calc(((100vw - 144px) / 4) * 0.5616);
        }
      }
    }
  }
}

// 移动端 三个排布
@media screen and (max-width: 768px) {
  .responsive {
    @apply w-screen flex;

    .responsive-left {
      @apply w-0;
    }
    .responsive-right {
      @apply w-0;
    }
    .responsive-center {
      @apply w-screen  m-0;
      .items {
        @apply w-screen pl-4;
        .item {
          @apply mr-7;
          width: calc((100vw - 48px) / 2);
          height: calc(((100vw - 48px) / 2) * 1.5);
        }
        .itemWidth {
          @apply mr-7;
          width: calc((100vw - 48px) / 2);
        }
        .itemLive {
          @apply mr-7;
          width: calc((100vw - 78px) / 2);
          height: calc(((100vw - 48px) / 2) * 0.5616);
        }
      }
    }
  }
}
